$body-font-size: 16px;

$color-white:             #FFFFFF;

$color-black-light:       #666666;
$color-black:             rgba(0, 0, 0, 0.87);

$color-blue-1:        #5B78B8;
$color-blue-2:      #B7CDFF;
$color-blue-3:              #4062AE;
$color-blue-4:         #182E5E;
$color-blue-facebook:     #4267B2;

$color-gray-1:            #F8F8F8;
$color-gray-2:            #C4C4C4;
$color-gray-3:            #DFDFDF;
$color-gray-4:            #C1C1C1;
$color-gray-5:            #D6D6D6;
$color-gray-6:            #DCDCDC;
$color-gray-7:            #727272;
$color-gray-8:            #6F7A82;
$color-gray-9:            #464646;
$color-gray-10:           #747474;

$color-green-light:       #D6EBE6;
$color-green-dark:        #008265;
$color-green-success:     #4BB543;

$color-red:               #E14842;

$color-flu-orange:        #F9CB6F;

$color-flu-green:         #DAF96F;

$color-cappuccino:        #574646;

$color-mosaic-shadow:     rgba(0, 0, 0, .16);
$color-mosaic-shadow-hover:     rgba(0, 0, 0, .31);

$xs: 576px;	// Max 576px	_xs-*
$sm: 800px;	// Max 768px	_sm-*
$md: 1024px;	// Max 1024px	_md-*
$mlg: 1170px;
$lg: 1280px;	// Max 1280px	_lg-*
$xl: 1390px;	// Max 1390px custom

$fonts: (
  arial: 'Arial',
  body: 'Roboto',
  title: 'Montserrat'
);

$headerHeight: 4.279375rem;
$sideNavigationWidth: 8.625rem;

$mapWidthXl: 22.8125rem;
$mapWidthLg: 22.8125rem;
$mapWidthMd: 15.208rem;

$supportAsideXl: 22.2125rem;
$supportAsideLg: 22.2125rem;
$supportAsideMd: 14rem;

@import './mixin.scss';
