@import './variables';

.bg {
    &-blue {
        background-color: $color-blue-3;
        &--light {
            background-color: $color-blue-1;
            color: #FFF;
        }
        &--lighter {
            background-color: $color-blue-2;
            color: #000;
        }
    }
}
