@import './variables';

.button {
  color: $color-black;
  font-size: 0.875rem;
  @include font('body', 'regular');
  min-width: 6.875rem;
  padding: 0.375em 1.6875em;
  position: relative;

  &-primary {
    color: $color-white;
    background-color: $color-blue-3;
  }

  &-gray {
    border: 1px solid $color-gray-2;
    background-color: $color-gray-3;
  }

  &-dark-blue {
    color: $color-white;
    background-color: $color-blue-4;
  }

  &-round {
    border-radius: 3.125rem;

    &ed {
      border-radius: 0.25rem;
    }
  }

  &-tab {
    font-size: 1rem;
    @include font('body', 'semiBold');
    text-align: right;
    color: $color-gray-8;
    padding: 1rem 1.25rem;

    &--active {
      background-color: $color-gray-1;
    }
  }

  &-burguer {
    width: 2.5rem;
    min-width: inherit;
    height: 2.5rem;
    padding: 0;
    position: relative;

    span {
      width: 100%;
      height: 0.125rem;
      display: block;
      background-color: #fff;
      position: absolute;

      &:not(:first-of-type):not(:last-of-type) {
        width: 75%;
      }

      &:first-of-type {
        top: 0.4375rem;
      }

      &:last-of-type {
        bottom: 0.4375rem;
      }
    }
  }

  &-quick-search {
    font-size: 2rem;
    width: 2.5rem;
    min-width: inherit;
    height: 2.5rem;
    padding: 0;

    .icon {
      transform: translate(-50%, -50%);
    }
  }

  &-login {
    // $lh: (26/12);
    @include font('body', 'normal');
    // font-size: 1.375rem;
    letter-spacing: 0.01em;
    color: #fff;

    background-color: $color-blue-4;
  }

  &-ghost {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  &-social {
    // $lh: (26/12);
    @include font('body', 'normal');
    // font-size: 1.375rem;
    letter-spacing: 0.01em;
    color: $color-white;
    padding: 0.8125em 1em;
    padding-left: 3.375em;
    margin-bottom: 1.6875rem;

    .icon {
      left: 0.7rem;
    }

    &--google {
      color: $color-gray-9;
      background-color: $color-white;

      img {
        width: 1.875em;
        height: 1.875em;
        display: block;
      }
    }

    &--facebook {
      color: $color-white;
      background-color: $color-blue-facebook;

      .icon {
        font-size: 1.5em;
      }
    }
  }

  &-icon {
    &--left {
      padding-left: 2.4375rem;

      .icon {
        left: 0.625rem;
      }
    }

    &--right {
      padding-right: 2.4375rem;

      .icon {
        right: 0.625rem;
      }
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
