@font-face {
  font-family: 'icomoon';
  src: url("icomoon.eot?fe7jjj");
  src: url("icomoon.eot?fe7jjj#iefix") format("embedded-opentype"), url("icomoon.ttf?fe7jjj") format("truetype"), url("icomoon.woff?fe7jjj") format("woff"), url("icomoon.svg?fe7jjj#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-heart-green-dark:before {
  content: "\e914";
  color: #03dac6; }

.icon-heart-green-light:before {
  content: "\e915";
  color: #80ece2; }

.icon-heart:before {
  content: "\e916"; }

.icon-facebook-official:before {
  content: "\e913"; }

.icon-calendar-black:before {
  content: "\e912"; }

.icon-timeline-present:before {
  content: "\e911";
  color: #008265; }

.icon-search:before {
  content: "\e910";
  color: #fff; }

.icon-menu-heart:before {
  content: "\e90f";
  color: #4062ae; }

.icon-menu-explore:before {
  content: "\e90a";
  color: #4062ae; }

.icon-menu-account:before {
  content: "\e90b";
  color: #4062ae; }

.icon-menu-support:before {
  content: "\e90c";
  color: #4062ae; }

.icon-menu-routs:before {
  content: "\e90d";
  color: #4062ae; }

.icon-angle-right:before {
  content: "\e90e";
  color: #fff; }

.icon-marker-1:before {
  content: "\e900";
  color: #4062ae; }

.icon-marker-2:before {
  content: "\e901";
  color: #7695db; }

.icon-marker-3:before {
  content: "\e902";
  color: #b7cdff; }

.icon-facebook-square:before {
  content: "\e903";
  color: #fff; }

.icon-instagram:before {
  content: "\e904";
  color: #fff; }

.icon-book-mark:before {
  content: "\e905";
  color: #03dac6; }

.icon-calendar .path1:before {
  content: "\e906";
  color: #707070; }

.icon-calendar .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: #03dac6; }

.icon-hot .path1:before {
  content: "\e908";
  color: #03dac6; }

.icon-hot .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: #707070; }
