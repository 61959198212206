@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
@import "~react-toastify/scss/main";

@import "./styles/utils/gridlex.scss";
@import "./fonts/icons/style.css";
@import "./styles/index.scss";

.container {

}

[class*="--col"] {
  box-sizing: border-box;
  padding: 0 0.5rem;
  max-width: 100%;
}

.main-content {
  &--column {
    // width: calc(100% - $sideNavigationWidth);
    // padding: 0;
    overflow: hidden;

    @media only screen and (min-width: 64em) {
      width: calc(100% - 21.125rem);
      margin-left: 21.125rem;
    }
  }
}

.container {

}

.bg-gradient {
  &-blue {
    background: -webkit-gradient(linear,left top,right top,from(#01a9ac),to(#01dbdf));
    background: linear-gradient(to right,#01a9ac,#01dbdf);
  }
  &-green {
    background: -webkit-gradient(linear,left top,right top,from(#0ac282),to(#0df3a3));
    background: linear-gradient(to right,#0ac282,#0df3a3);
  }
  &-yellow {
    background: -webkit-gradient(linear,left top,right top,from(#fe9365),to(#feb798));
    background: linear-gradient(to right,#fe9365,#feb798);
  }
  &-red {
    background: -webkit-gradient(linear,left top,right top,from(#fe5d70),to(#fe909d));
    background: linear-gradient(to right,#fe5d70,#fe909d);
  }
  &-default {
    background: -webkit-gradient(linear,left top,right top,from($color-blue-3),to($color-blue-1));
    background: linear-gradient(to right,$color-blue-3,$color-blue-1);
  }
}

.loading-fullpage {
  display: block;
  width: 100%;
  height: 100vh;
  position: relative;

  .fetching-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
